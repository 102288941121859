<template>
  <el-drawer ref="drawerRef" v-model="dialogFormVisible" :title="title" :size="size" :before-close="close" :direction="direction" custom-class="demo-drawer">
    <el-form ref="formRef" label-width="120px" :model="form" :rules="rules" :label-position="labelPosition">
      <el-form-item label="手机号" prop="phone" v-if="title == '添加'">
        <el-input v-model.trim="form.phone" style="width:60%;" />
        <el-button style="margin-left: 20px;" @click="findUser">查询</el-button>
      </el-form-item>
      <el-form-item label="嘉宾名称" prop="nameCN">
        <el-input v-model.trim="form.nameCN" />
      </el-form-item>
      <el-form-item label="英文嘉宾名称" prop="nameEN">
        <el-input v-model.trim="form.nameEN" />
      </el-form-item>

      <el-form-item label="机构名称" prop="companyCn">
        <el-input v-model.trim="form.companyCn" />
      </el-form-item>
      <el-form-item label="英文机构名称" prop="companyEN">
        <el-input v-model.trim="form.companyEN" />
      </el-form-item>
      <el-form-item label="职位名称" prop="positionCn">
        <el-input v-model.trim="form.positionCn" />
      </el-form-item>
      <el-form-item label="英文职位名称" prop="positionEN">
        <el-input v-model.trim="form.positionEN" />
      </el-form-item>

      <el-form-item label="头像" prop="avatar">
        <div class="upLoadImg">
          <wmt-image :src="form.avatar" @deleteImg="deleteLogo"></wmt-image>
          <div>
            <el-button @click="upLoadLogo">上传图片</el-button>
            <p>建议上传比例为1：1，200px*200px，小于5MB的图片</p>
          </div>
        </div>
      </el-form-item>

    </el-form>
    <div class="demo-drawer__footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>

    <vue-cropper ref="vabCropperRef" @imgInfo="logoInfo" :title="'头像'" :option="brandLogoOption" />

  </el-drawer>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  defineAsyncComponent,
  reactive,
  toRefs,
  onMounted,
} from 'vue'
import {
  updateTranslationGuest,
  queryGuestByCode,
} from '@/api/exhibitionManage'
import { useStore } from 'vuex'
import VueCropper from '@/extra/VueCropper'
import wmtImage from '@/components/wmt-image'
import { getUserByPhone } from '@/api/user'
// 引入下拉数选择
export default defineComponent({
  name: 'UserManagementEdit',
  components: {
    VueCropper,
    wmtImage,
  },
  props: {},
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    // 自定义手机验证

    const state = reactive({
      formRef: null,
      labelPosition: 'left',
      form: {
        appKey: 'WX_MINI_WEMEET',
      },
      departmentData: [],
      rules: {
        nameCN: [
          { required: true, trigger: 'blur', message: '请输入嘉宾名称' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      wcs: [],
      size: '50%',
      direction: 'rtl',
      vabCropperRef: null,
      brandLogoOption: {
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
        info: true, // 裁剪框的大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
        fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        full: true, // 是否输出原图比例的截图
        enlarge: '1', // 图片根据截图框输出比例倍数
        mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
      },
    })

    const showEdit = async (row) => {
      console.log(row)
      if (!row) {
        state.title = '添加'
      } else {
        state.title = '编辑'

        const { data } = await queryGuestByCode({ code: row.code })

        data.nameCN = data.name
        data.companyCn = data.company
        data.positionCn = data.position
        data.avatar = data.avatar != '' && data.avatar != 'null' && data.avatar != null ? data.avatar : ''
        state.form = Object.assign({}, data)
      }
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        appKey: 'WX_MINI_WEMEET',
        avatar: ''
      }
      state.dialogFormVisible = false
    }
    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          console.log(state.form)
          await updateTranslationGuest(state.form)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    const upLoadLogo = () => {
      state['vabCropperRef'].dialogVisible = true
    }

    const logoInfo = (avatar) => {
      state.form.avatar = avatar
    }

    const deleteLogo = () => {
      state.form.avatar = ''
    }

    const findUser = async () => {
      console.log('findUser')
      if (!!state.form.phone) {
        let phoneParams = { phone: state.form.phone }
        const { data } = await getUserByPhone(phoneParams)
        state.form.nameCN = data.name
        state.form.companyCn = data.company
        state.form.positionCn = data.position
        state.form.avatar = data.avatar
        state.form.uid = data.weMeetUid
      } else {
        proxy.$baseMessage(`请输入手机号`, 'error')
      }
    }

    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
      upLoadLogo,
      logoInfo,
      deleteLogo,
      findUser,
    }
  },
})
</script>


<style lang="scss" scoped>
.demo-drawer {
  position: relative;
}
.demo-drawer__footer {
  position: fixed;
  right: 20px;
  bottom: 10px;
}
</style>